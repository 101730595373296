<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-wrapper">
            <div class="left">
              <div class="profile-info">
                <img class="img" src="./../images/profile.png" />
                <div class="text">
                  <div class="title" v-text="name + ' ' + surname" />
                  <div v-if="steamId" class="desc small gray" v-text="'Steam ID:'" />
                  <div v-if="steamId" class="desc small" v-text="steamId" />
                </div>
              </div>
              <div class="buttons">
                <button class="button" v-text="$t('EDITE PROFILE')" @click="$parent.openProfileModal" />
                <button class="button" v-text="$t('LOG OUT')" @click="$parent.logout" />
              </div>
            </div>
            <div class="right">
              <div class="title">
                <img class="img" src="./../images/history.svg" />
                <span v-text="$t('Order history')" />
              </div>
              <div class="profile-table" v-if="$parent.orderHistory">
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>{{ $t("Date") }}</td>
                          <td>{{ $t("Skin name") }}</td>
                          <td>{{ $t("Status") }}</td>
                          <td>{{ $t("Total") }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                          <td>{{ item.created_at_foramtted }}</td>
                          <td>{{ item.title }}</td>
                          <td>{{ $t(item.status) }}</td>
                          <td>{{ item.sum }} {{ $parent.currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: [],
  data: function() {
    return {
      name: '',
      surname: '',
      steamId: ''
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        if (res.status == 200) {
          self.name = res.data.name;
          self.surname = res.data.surname;
          self.steamId = res.data.steamId;
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    
  }
}
</script>

