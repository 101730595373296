<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="column">
            <a href="index.html" class="logo">
              <img src="./../images/logo.svg" class="img" />
            </a>
            <div :class="['payment-methods', {'active': paymentMethodsIsVisible}]">
              <button 
                class="button payment-methods-button" 
                v-text="$t('Our Payment methods')" 
                @click="paymentMethodsIsVisible = !paymentMethodsIsVisible"
              />
              <div class="list">
                <div class="item">
                  <img src="./../images/methods/mc_symbol.svg" class="img" />
                  <div class="title very-small" v-text="'Mastercard'"/>
                </div>
                <div class="item">
                  <img src="./../images/methods/visa.svg" class="img" />
                  <div class="title very-small" v-text="'Visa'"/>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="social-list" v-if="$parent.socialLinks && $parent.socialLinks.length">
              <a v-for="(item,i) in $parent.socialLinks" :key="i" :href="item.link" target="_blank" class="social__item">
                <img :src="item.img" class="img" />
              </a>
            </div>
            <ul class="nav">
              <template v-for="item in $parent.textPageList">
                <li class="nav__item" :key="item.id">
                  <a @click="$parent.goToPage(item.id)">
                    {{ $t(item.title) }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="title" v-text="$t('Want to get more special offers? ')"/>
          <div class="desc" v-text="$t('Leave your e-mail address')"/>
          <div class="input-container">
            <input type="text" v-model="email" placeholder="Enter your email">
            <button class="button" v-text="$t('SEND')" @click="submit" />
          </div>
          <transition name="fade">
            <div v-if="$parent.successEmail" class="desc small green">{{$parent.successEmail}}</div>
          </transition>
          <transition name="slide">
            <div v-if="$parent.errorEmail" class="error-desc desc red">{{$parent.errorEmail}}</div>
          </transition>
        </div>
      </div>
    </div>
    <div class="copyright-section" v-if="$parent.footerRequisites || $parent.footerCopyright">
      <div class="wrapper">
        <div class="desc" v-if="$parent.footerRequisites" v-text="$parent.footerRequisites" />
        <div class="right">
          <div class="desc" v-if="$parent.footerSupportEmail" v-text="$parent.footerSupportEmail" />
          <div class="desc" v-if="$parent.footerCopyright" v-text="$parent.footerCopyright" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {
      paymentMethodsIsVisible: false,
      email: '',

    };
  },
  methods: {
    submit() {
      let data = {
        "email": this.email,
      }
      this.$emit('submitSubscribtion', data);
    },
  },
  mounted() {},
};
</script>
