<template>
  <div class="modal profile-modal">
    <div class="overlay" @click="$parent.closeProfileModal"></div>
    <div class="wrapper">
      <div class="container">
        <img src="./../images/close.svg" @click="$parent.closeProfileModal" class="close"/>
        <div class="nav">
          <button :class="['title very-small', {'active': activeTab == 'personal'}]" @click="activeTab = 'personal'">
            Personal information
          </button>
          <button :class="['title very-small', {'active': activeTab == 'password'}]" @click="activeTab = 'password'">
            Change password
          </button>
          <button :class="['title very-small', {'active': activeTab == 'trade-link'}]"
                  @click="activeTab = 'trade-link'">Trade link
          </button>
          <button :class="['title very-small', {'active': activeTab == 'settings'}]" @click="activeTab = 'settings'">
            Settings
          </button>
        </div>
        <div class="content">
          <div class="profile-form">
            <div class="profile-form-wrapper">

              <div class="profile-form-wrapper" v-if="activeTab == 'personal'">
                <div class="title small">{{ $t("Personal Information") }}</div>
                <div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> {{ $t("First name") }}:</div>
                    <input type="text" placeholder="First name" v-model="name"/>
                  </div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> {{ $t("E-mail") }}:</div>
                    <input type="email" placeholder="E-mail" v-model="email"/>
                  </div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> {{ $t("Last name") }}:</div>
                    <input type="text" placeholder="Last name" v-model="surname"/>
                  </div>
                  <div class="input-container" v-if="countryOptions && countryOptions.length">
                    <div class="desc"><span class="red">*</span> {{ $t("Phone") }}:</div>
                    <VuePhoneNumberInput v-model="phone" :only-countries="countryOptions"
                                         :default-country-code="convertedShortName" @update="phoneDataUpdate($event)"/>
                  </div>
                </div>
                <button class="button dark" @click="updateProfileData">
                  <span>{{ $t("Save") }}</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="profileUpdateErrorMessaage">{{ profileUpdateErrorMessaage }}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="profileUpdateSuccessMessaage">{{ profileUpdateSuccessMessaage }}</div>
                </transition>
              </div>

              <div class="profile-form-wrapper" v-if="activeTab == 'password'">
                <div class="title small">{{ $t("Change Password") }}</div>
                <div class="input-container">
                  <div class="desc"><span class="red">*</span> {{ $t("Current password") }}:</div>
                  <input type="text" placeholder="Current password" v-model="curPass"/>
                </div>
                <div class="input-container long">
                  <div class="desc"><span class="red">*</span> {{ $t("New password") }}:</div>
                  <input type="text" placeholder="New password" v-model="newPass"/>
                </div>
                <div class="input-container long">
                  <div class="desc"><span class="red">*</span> {{ $t("Confirm password") }}:</div>
                  <input type="text" placeholder="Confirm password" v-model="repeatPass"/>
                </div>
                <div class="pass-rules">
                  <div class="desc bold">{{ $t("Password must contain") }}:</div>
                  <div class="desc">-
                    {{ $t("At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a-z), 1 number (0-9)") }}
                  </div>
                </div>
                <button class="button dark" @click="updatePassword">
                  <span>{{ $t("Save") }}</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="passUpdateErrorMessaage">{{ passUpdateErrorMessaage }}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="passUpdateSuccessMessaage">{{ passUpdateSuccessMessaage }}</div>
                </transition>
              </div>

              <div class="profile-form-wrapper profile-trade-link" v-if="activeTab == 'trade-link'">
                <div class="title small">{{ $t("Trade link") }}</div>

                <div class="description">
                  To receive trades on Skin-flare, you must enter your Steam Trade URL here. <br/>
                  You can get your Steam Trade URL on the Steamcommunity page
                </div>

                <div class="input-container">
                  <div class="desc"><span class="red">*</span> {{ $t("Enter trade link") }}:</div>
                  <input required type="text" placeholder="Enter trade link" v-model="tradeLink"/>
                </div>
                <!--                <div class="pass-rules">-->
                <!--                  <div class="desc bold">{{$t("Password must contain")}}:</div>-->
                <!--                  <div class="desc">- {{$t("At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a-z), 1 number (0-9)")}}</div>-->
                <!--                </div>-->
                <div class="buttons">
                  <button class="button black" @click="$parent.closeProfileModal">
                    <span>{{ $t("Cancel") }}</span>
                  </button>
                  <button class="button dark" @click="updateTradeLink">
                    <span>{{ $t("Save Changes") }}</span>
                  </button>
                </div>
                <transition name="fade">
                  <div class="desc red" v-if="tradeLinkUpdateErrorMessage">{{ tradeLinkUpdateErrorMessage }}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="tradeLinkUpdateSuccessMessage">{{ tradeLinkUpdateSuccessMessage }}</div>
                </transition>
              </div>

              <div class="profile-form-wrapper profile-settings" v-if="activeTab == 'settings'">
                <div class="title small">{{ $t("Block your account") }}</div>

                <div class="description">
                  You can block your account instead of deleting it. Your account will be blocked until you activate it.
                </div>

                <div class="buttons">
                  <button class="button dark" @click="blockProfile">
                    <span>{{ $t("Block account") }}</span>
                  </button>
                </div>

                <div class="title small">{{ $t("Delete your account") }}</div>

                <div class="buttons">
                  <button class="button dark" @click="deleteProfile">
                    <span>{{ $t("Delete account") }}</span>
                  </button>
                </div>

                <transition name="fade">
                  <div class="desc red" v-if="successMessage">{{ successMessage }}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="errorMessage">{{ errorMessage }}</div>
                </transition>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

export default {
  name: 'ProfileModal',
  props: [],
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      tradeLink: '',
      tradeLinkUpdateSuccessMessage: '',
      tradeLinkUpdateErrorMessage: '',
      successMessage: '',
      errorMessage: '',
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptions: [],
      activeTab: 'personal'
    }
  },
  methods: {
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
          .then((res) => {
            this.countryOptions = res.data.payload.map(obj => obj.iso);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
          })
    },
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
          .then((res) => {
            if (res.status == 200) {
              self.email = res.data.email;
              self.name = res.data.name;
              self.surname = res.data.surname;
              self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
              self.profilePhone = res.data.phone;
              self.tradeLink = res.data.steam_trade_link ?? '';
              self.getAllCountryCodes();
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
          })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
          .then((res) => {
            if (res.data.status == "OK") {
              this.countryCodeList = res.data.payload;
              this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '');
              this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
          })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
        return match[0];
      } else {
        return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phoneFull,
        "surname": this.surname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
          .then((res) => {
            if (res.data.status == "OK") {
              this.profileUpdateSuccessMessaage = 'Success';
              setTimeout(() => {
                this.profileUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.profileUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.profileUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
          .then((res) => {
            if (res.data.status == "OK") {
              this.passUpdateSuccessMessaage = 'Success';
              this.curPass = '',
                  this.newPass = '',
                  this.repeatPass = '';
              setTimeout(() => {
                this.passUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.passUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.passUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    updateTradeLink() {
      this.$http.put(process.env.VUE_APP_API + 'user/update-trade-link', {
        url: this.tradeLink
      }).then((res) => {
            if (res.data.status == "OK") {
              this.tradeLinkUpdateSuccessMessage = 'Success';
              setTimeout(() => {
                this.tradeLinkUpdateSuccessMessage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.tradeLinkUpdateErrorMessage = res.response.data.message;
            setTimeout(() => {
              this.tradeLinkUpdateErrorMessage = '';
            }, 3000);
          })
    },
    deleteProfile() {
      this.$http.delete(process.env.VUE_APP_API + 'user/delete', {
        url: this.tradeLink
      }).then((res) => {
        if (res.data.status == "OK") {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }

          this.$parent.closeProfileModal();
          this.$parent.isAuth = false;

          // this.successMessage = 'Success';
          // setTimeout(() => {
          //   this.successMessage = '';
          // }, 3000);
        }
      }).catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.errorMessage = res.response.data.message;
            setTimeout(() => {
              this.errorMessage = '';
            }, 3000);
          })
    },
    blockProfile() {
      this.$http.post(process.env.VUE_APP_API + 'user/block', {
        url: this.tradeLink
      }).then((res) => {
        if (res.data.status == "OK") {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }

          this.$parent.closeProfileModal();
          this.$parent.isAuth = false;

          // this.successMessage = 'Success';
          // setTimeout(() => {
          //   this.successMessage = '';
          // }, 3000);
        }
      }).catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.errorMessage = res.response.data.message;
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      })
    }
  },
  mounted() {
    this.getProfile();
    this.getCountries();
  },
  computed: {}
}
</script>
