<template>
  <div class="item-wrapper">
    <div class="item-content">
      <div v-if="tag" class="tag">
        <span>{{ tag }}</span>
      </div>
      <div class="preview">
        <img @click="goToSliderItem(item)" :src="imgDomain + item.img_url" class="img" />
        <transition name="fade">
          <span class="title" v-if="addToCartChosenItem == item.id">{{ $t("Added") }}</span>
        </transition>
      </div>
      <div class="text">
        <a @click="goToSliderItem(item)" class="desc">{{ item.title }}</a>
        <div class="price title">
          <span class="discount" v-if="item.old_price != '0.00'"
            >{{ item.old_price }} <span class="currency">{{ currencySymbol }}</span></span
          >
          <span
            >{{ item.price }} <span class="currency">{{ currencySymbol }}</span></span
          >
        </div>
      </div>
    </div>
    <a @click="$emit('addToCart', item, item.id)" class="link button">
      <span>{{ $t("Add to cart") }}</span>
    </a>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProductCard",
  props: ["item", "addToCartChosenItem", "sliderIsClickble", "tag", "currency"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol'])
  }
};
</script>
