<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../images/logo.svg" class="img" />
          <img src="./../images/logo-mini.svg" class="img mob" />
        </router-link>
        <div class="social-list">
          <a v-for="(item,i) in $parent.socialLinks" :key="i" :href="item.link" target="_blank" class="social__item">
            <img :src="item.img" class="img" />
          </a>
        </div>
      </div>
      <div class="right">
        <router-link to="/contacts" class="contact-link">
          <img src="./../images/contact.svg" class="img" />
          <span class="title very-small">Contact Us</span>
        </router-link>
        <div class="select-container currency-select-container language-select-container" v-if="currencies && currencies.length > 1">
          <Select2 class="select2 single currency-select" v-model="curr" @select="selectCurrency($event)"  :options="currencies" :placeholder="curr" />
        </div>
        <div class="select-container language-select-container" v-if="currentLanguage && languages && languages.length > 1">
          <Select2 v-if="languages.length > 1" class="select2 single currency-select" v-model="language" @select="selectLanguage($event)" :options="languages" :placeholder="currentLanguageName" />
        </div>
        <router-link to="/cart" v-if="$parent.isAuth" class="icon">
          <img src="./../images/cart.svg" class="img" />
          <img src="./../images/cart2.svg" class="img-hover" />
          <div v-if="cartContents && cartContents.length" class="indicator">
            {{ cartContents.length }}
          </div>
        </router-link>
        <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
          <img src="./../images/user.svg" class="img" />
          <img src="./../images/user2.svg" class="img-hover" />
        </router-link>
        <div class="auth-container" v-else>
          <div :class="['auth-dropdown', {'active': $parent.authDropdownIsVisible}]">
            <div class="auth-dropdown__item">
              <div class="title very-small">Registered users</div>
              <div class="desc small">Have an account?</div>
              <a href="https://sso.skin-flare.com/login" class="title very-small">Sign in</a>
            </div>
            <div class="auth-dropdown__item">
              <div class="title very-small">New customer</div>
              <div class="desc small">Create an account to get started today.</div>
              <a href="https://sso.skin-flare.com/registration" class="title very-small">Sign UP</a>
            </div>
          </div>
          <button :class="['button sign-button small', {'active': $parent.authDropdownIsVisible}]" @click="$emit('toggleAuthDropdown', !$parent.authDropdownIsVisible)">{{ $t("Sign in") }}</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Select2 from "vue3-select2-component";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  props: ["cartContents"],
  components: {
    Select2,
  },
  data: function () {
    return {
    };
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapState("app", ["currencies", "currency"]),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
    curr: {
      set(currency) {
        this.setCurrency(currency)
      },
      get() {
        return this.currency;
      }
    }
  },
  watch: {
    currentLanguage() {
      if (this.$route.name === "TextPage") {
        this.$router.push({ path: "/" });
      }
    },
  },
  methods: {
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
    ...mapMutations("app", ["setCurrentLanguage", 'setCurrency']),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>
