<template>
  <main class="main  page-inside">
    <div class="main-wrapper">
      <section class="section hero-section recommended-section product-list-section">
        <div class="wrapper">
          <router-link to="/" class="back"><span>Back to main</span></router-link>
          <div class="title">Suggestions for you</div>
          <div class="list products sale-products">
            <div class="item" v-for="(item, index) in $parent.recommendedProducts" :key="index">
              <ProductCard :item="item" :tag="'Recommend'" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue'

export default {
  name: 'Recommend',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>