<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignInModal')" src="./../images/close.svg" />
            <div :class="['form-fields login-content', { hidden: !loginContentVisible }]">
              <div class="form-fields-wrapper">
                <img class="logo-img" src="./../images/logo2.svg" />
                <div class="title small">{{ $t("Login") }}</div>
                <div class="modal-bottom">
                  <div class="desc">{{ $t("New costumer?") }}</div>
                  <div class="link" @click="$parent.openSignUpModal">
                    {{ $t("Sign UP") }}
                  </div>
                </div>
                <label>
                  <div class="desc">{{ $t('E-mail:') }}</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <label>
                  <div class="desc">{{ $t('Password:') }}</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitLogin">
                    <span>{{ $t("SIGN IN") }}</span>
                  </button>
                  <div class="link" @click="switchToforgotPass">
                    {{ $t("Forgot password") }}
                  </div>
                </div>
                <div class="steam-block">
                  <div class="desc small">{{ $t('or sign up with:') }}</div>
                  <div class="list">
                    <img @click="$parent.submitLoginSteam" class="img" src="./../images/steam.svg" />
                    <img @click="$parent.submitLoginGoogle" class="img" src="./../images/google.svg" />
                  </div>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
                <button class="button resend-link-button" v-if="!isVerified" @click="$emit('resendConfirmation', email)">
                  <span>{{ $t("Resend Confirmation Link") }}</span>
                </button>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', { hidden: !forgotPassContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t("Forgot password") }}?</div>
                <div class="modal-bottom">
                  <div class="desc">{{ $t("Have account") }}?</div>
                  <div class="link switch-login" @click="switchToLogin()">
                    {{ $t("Login") }}
                  </div>
                </div>
                <label>
                  <div class="desc">{{ $t("Email") }}:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitForgotPass">
                    <span>{{ $t("Recover") }}</span>
                  </button>
                </div>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">
                    {{ $parent.successMessage }}
                  </div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInModal",
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: "",
      pass: "",
    };
  },
  mounted() {},
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(() => {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100);
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(() => {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login", loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email,
      };
      this.$emit("forgotPass", data);
    },
  },
};
</script>
