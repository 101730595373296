<template>
  <div class="menu">
    <router-link to="/cart" v-if="$parent.isAuth" class="icon">
      <img src="./../images/cart.svg" class="img" />
      <div v-if="cartContents && cartContents.length" class="indicator">
        {{ cartContents.length }}
      </div>
    </router-link>
    <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
      <img src="./../images/user.svg" class="img" />
    </router-link>
    <div class="auth-container" v-else>
      <div :class="['auth-dropdown', {'active': $parent.authDropdownIsVisible}]">
        <div class="auth-dropdown__item">
          <div class="title very-small">Registered users</div>
          <div class="desc small">Have an account?</div>
          <button class="title very-small" @click="$parent.openSignInModal">Sign in</button>
        </div>
        <div class="auth-dropdown__item">
          <div class="title very-small">New customer</div>
          <div class="desc small">Create an account to get started today.</div>
          <button class="title very-small" @click="$parent.openSignUpModal">Sign UP</button>
        </div>
      </div>
      <button :class="['button sign-button small', {'active': $parent.authDropdownIsVisible}]" @click="$emit('toggleAuthDropdown', !$parent.authDropdownIsVisible)">{{ $t("Sign in") }}</button>
    </div>
    
    <div class="select-container currency-select-container language-select-container"  v-if="currencies && currencies.length && currencies.length > 1">
      <Select2 class="select2 single currency-select" v-model="curr" @select="selectCurrency($event)"  :options="currencies" :placeholder="curr" />
    </div>
    <div class="select-container language-select-container" v-if="currentLanguage && languages && languages.length && languages.length > 1">
      <Select2 class="select2 single currency-select" v-model="language" @select="selectLanguage($event)" :options="languages" :placeholder="currentLanguageName" />
    </div>
    <button class="contact-link">
      <img src="./../images/contact.svg" class="img" />
      <span class="title very-small">Contact Us</span>
    </button>

  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/

import Select2 from "vue3-select2-component";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Menu",
  props: ['cartContents'],
  components: {
    Select2,
  },
  data: function () {
    return {
      authDropdownIsVisible: false
    };
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapState("app", ["currencies", "currency"]),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
    curr: {
      set(currency) {
        this.setCurrency(currency)
      },
      get() {
        return this.currency;
      }
    }
  },
  methods: {
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    ...mapMutations("app", ["setCurrentLanguage", 'setCurrency']),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>
