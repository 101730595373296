<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="left">
                  <div class="select-container" v-if="categoryOptions && categoryOptions.length">
                    <Select2 class="select2 single" v-model="category" @select="selectCategory($event)" :options="categoryOptions" :placeholder="$t('Type')" />
                  </div>
                  <div class="select-container" v-if="qualityOptions && qualityOptions.length">
                    <Select2 class="select2 single" v-model="quality" @select="selectQuality($event)" :options="qualityOptions" :placeholder="$t('Quality')" />
                  </div>
                  <div class="select-container input-container">
                    <input type="text" placeholder="Search" v-model="search" v-on:keyup.enter="onEnter"/>
                    <button class="button" @click="onEnter">
                      <img src="./../images/glass.svg" class="img"/>
                    </button>
                  </div>
                </div>
                <div class="right">
                  <div class="select-container" v-if="sortOptions && sortOptions.length">
                    <Select2 class="select2 single" v-model="sort" @select="selectSort($event)" :options="sortOptions" :placeholder="$t('Sort')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="products-container">
            <transition name="fade">
              <div class="list products" v-if="productList">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
                </div>
              </div>
            </transition>
          </div>
          <pagination :per-page="18" v-if="totalProducts" v-model="activePage" :records="totalProducts" @paginate="changePage"/>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import {mapGetters} from "vuex";
import Select2 from "vue3-select2-component";

export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem", "productsFilteredBySearch"],
  components: {
    Pagination,
    ProductCard,
    Select2
  },
  data: function () {
    return {
      imgDomain: "",
      sort: "desc",
      category: "",
      quality: "",
      activePage: 1,
      totalProducts: null,
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 200000,
      productList: {},
      typeListIsVisible: true,
      qualityListIsVisible: false,
      priceListIsVisible: true,
      specialCategory: "",
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        },
        {
          id: 'asc',
          text: 'Lowest price first'
        }
      ],
      search: ''
    };
  },
  watch: {
    productsFilteredBySearch: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    sort() {
      this.activePage = 1;
      this.filterProducts();
    },
    quality() {
      this.activePage = 1;
      this.filterProducts();
    },
    category() {
      this.activePage = 1;
      this.filterProducts();
    },
    $route() {
      this.activePage = 1;
      this.checkRouteParams();
    },
  },
  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
  },
  methods: {
    searchSubmit() {
      this.$emit('searchSubmit', this.search)
    },
    onEnter() {
      this.searchSubmit();
    },
    selectQuality(event) {
      this.quality = event.text;
    },
    selectCategory(event) {
      this.category = event.text;
    },
    selectSort(event) {
      this.sort = event.id;
    },
    checkRouteParams() {
      if (this.$route.params.type == "revolution-collection") {
        this.specialCategory = this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type != "all") {
        this.category = this.$route.params.type;
        this.specialCategory = "";
        console.log(this.category);
      } else if (this.$route.params.type && this.$route.params.type === "all") {
        this.category = "";
        this.specialCategory = "";
      }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All quality";
      this.category = "All types";
      this.barMinValue = 0;
      this.barMaxValue = 1200;
    },
    filterProducts() {
      let addCategory = "";
      if (this.category != "All types") {
        addCategory = "type=" + this.category;
      } else {
        addCategory = "type=";
      }

      let addQuality = "";
      if (this.quality != "All quality") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }
      let addPrice = "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;

      let addSort = "&sort=" + this.sort;

      let addPage = "&page=" + this.activePage;

      this.$http
        .get(process.env.VUE_APP_API + "items/list?" + addCategory + addQuality + addPrice + addSort + addPage + "&limit=18" + "&category=" + this.specialCategory + '&search=' + this.productsFilteredBySearch + '&currency=' + this.currency)
        .then((res) => {
          console.log(res);
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.pages = res.data.meta.total;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          newCategories.unshift("All types");
          this.newCategories = newCategories;
          this.categoryOptions = newCategories;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol', 'currency'])
  }
};
</script>
